<template>
  <div class="mt-2">
    <div class="align-middle">
      <div class="form-group form-inline my-1 ml-2">
        <label for="sel1"><h5>酸素濃縮装置</h5></label>
        <select  id="sel1" v-model="modelNumbers[0]" autocomplete="device kind" class="form-control ml-1">
          <option value="">未選択</option>
          <option value="5A+">オキシウェル5A+</option>
          <option value="5B+">オキシウェル5B+</option>
        </select>

        <label for="sel2" class="ml-3"><h5>ゲートウェイ</h5></label>
        <select  id="sel2" v-model="modelNumbers[1]" autocomplete="device kind" class="form-control ">
          <option value="">未選択</option>
          <option value="TCL-003">TCL-003</option>
        </select>

        <label for="sel2" class="ml-3"><h5>パルスオキシメータ</h5></label>
        <select  id="sel3" v-model="modelNumbers[2]" autocomplete="device kind" class="form-control">
          <option value="">未選択</option>
          <option value="TM1121">TM1121</option>
        </select>

        <label for="sel2" class="ml-3"><h5>血圧計</h5></label>
        <select  id="sel4" v-model="modelNumbers[3]" autocomplete="device kind" class="form-control">
          <option value="">未選択</option>
          <option value="UA-651BLE">UA-651BLE</option>
        </select>
      </div>
    </div>
    <CModal :show.sync="patientModal" size="lg" :centered="true" title="患者詳細情報">
      <div style="font-size: 18px;">
        <CRow>
          <CCol col="3"><label>患者名</label></CCol>
          <CCol>{{ patientDialogData.patientName }} (カルテID:{{ patientDialogData.karuteNo }})</CCol>
        </CRow>
        <CRow>
          <CCol col="3"><label>患者電話番号</label></CCol>
          <CCol>{{ patientDialogData.patientTel}}</CCol>
        </CRow>
        <CRow>
          <CCol col="3"><label>医療機関</label></CCol>
          <CCol>{{ patientDialogData.hospitalName }}</CCol>
        </CRow>
        <CRow>
          <CCol col="3"><label>担当医</label></CCol>
          <CCol>{{ patientDialogData.doctorName }}</CCol>
        </CRow>
        <CRow>
          <CCol col="3"><label>医療機関住所</label></CCol>
          <CCol>{{ patientDialogData.hospitalAddress }}</CCol>
        </CRow>

        <CRow>
          <CCol col="3"><label>電話番号</label></CCol>
          <CCol>{{ patientDialogData.hospitalTel }}</CCol>
        </CRow>
        <CRow>
          <CCol col="12"><label>設置機器</label></CCol>
        </CRow>
        <CRow class="ml-3" v-for="item in patientDialogData.deviceList">
            <CCol col="12 ml-3">
              {{ item.modelName }} {{ item.modelNumber }} {{ item.serialNumber }}
            </CCol>
        </CRow>
      </div>
      <template #footer-wrapper><span></span></template>
    </CModal>


    <CModal
        title="BDアドレス変更"
        :show.sync="bdAddressChageModal">

      <CRow class="mt-0">
        <CCol >
          <CInput class="mb-1"  horizontal label="BDアドレス" v-model="changeBdAddress" />
        </CCol>

      </CRow>

      <template #footer>
        <CButton size="sm" color="info" @click="bdAddressChageModal = false">閉じる</CButton>
        <CButton size="sm" color="danger" @click="bdAddressChange()">変更</CButton>
      </template>
    </CModal>


    <CModal
        title="シリアル番号変更"
        :show.sync="serialChangeModal">

      <CRow class="mt-0">
        <CCol >
          <CInput class="mb-1"  horizontal label="シリアル番号" v-model="changeSerialNumber" />
        </CCol>

      </CRow>

      <template #footer>
        <CButton size="sm" color="info" @click="bdAddressChageModal = false">閉じる</CButton>
        <CButton size="sm" color="danger" @click="serialNumberChange()">変更</CButton>
      </template>
    </CModal>



    <CCard class="p-0 m-0 grid-card">
      <CCardBody class="p-0">
        <MyBeeSearchBar @goodPaging="goodPaging" @changePage="changePage" @devSearch="devSearch"
                        :curr-page.sync="pagingParams.currPage"
                        :per-page.sync="pagingParams.perPage"
                        :per-page-list="perPageList"
                        :pages="pages"
                        :search-bar-place-holder="searchBarPlaceHolder"
                        :keyword.sync="pagingParams.keyword">
          <template slot="tools">
            <CButton color="info" size="sm" class="m-1" style="height:35px;" v-show="!showDeviceSelect" @click="csvDownload">CSVダウンロード</CButton>

            <CButton v-if="isProvider()" size="sm" color="warning mr-1"  variant="outline" :pressed="allBranchFlg" @click="toggleAllBranch">全事業所</CButton>
          </template>
        </MyBeeSearchBar>

        <BeeGridTable
            ref="bee"
            border
            stripe
            :showFilter="false"
            :columns="columns"
            :loading="isLoading"
            :data="rows"
            :showPager="false"
            :maxHeight="gridCardHeight - (isCollapsedNested ? 50:170 )"
            highlightRow
            no-data-text="データがありません。"
            no-filtered-data-text=""
            :context="context"
            :span-method="customSpan"
            :showVerticalScrollBar="false"
            :showHorizontalScrollBar="false">
          <!-- HEADER ソート可能にするために 必要-->
          <template slot-scope="{ column,index }" slot="modelNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="modelNumberHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="serialNumberHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="bdAddressHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="createdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="patientNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="patientIdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="agencyNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="agencyBranchNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="agencyIdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="gw_now_firmware_idHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="rent_fromHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="lostFlgHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="discardFlgHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>

          <!-- cell -->

          <template slot-scope="{ row,index }" slot="serialNumber">
            <div class="float-left">

              <template v-if="showDeviceSelect">
                <a href="" @click.prevent.stop="deviceSelect(row)">{{row.serialNumber}}</a>
              </template>
              <template v-else>
                <div class="text-left" v-if="isDeviceList && changeableSerialNumber()">
                  <a href="" @click.prevent.stop="showChangeSerialNumber({...row,index:index})" v-show="true">{{ row.serialNumber }}</a>
                </div>
                <div v-else class="text-left">
                  {{ row.serialNumber }}
                </div>
              </template>

            </div>
          </template>







          <template slot-scope="{ row,index }" slot="patientName">
            <div class="text-left">
                <span v-if="row.karuteNo != '*'"><a href="" @click.prevent.stop="showPatientDetail(row)" v-show="row.patientName">{{ row.patientName }}</a></span>
            </div>
          </template>

          <template slot-scope="{ row,index }" slot="patientId">
            <div class="text-left">{{row.patientId}}</div>
          </template>
          <template slot-scope="{ row,index }" slot="agencyName">
            <div class="text-left">{{row.agencyName}}</div>
          </template>
          <template slot-scope="{ row,index }" slot="agencyBranchName">
            <div class="text-left">{{row.agencyBranchName}}</div>
          </template>
          <template slot-scope="{ row,index }" slot="agencyId">
            <div class="text-left">{{row.agencyId}}</div>
          </template>

          <template slot-scope="{ row,index }" slot="gw_now_firmware_id">
            <div class="text-left">{{row.gw_now_firmware_id}}</div>
          </template>


          <template slot-scope="{ row,index }" slot="modelName">
            <div class="text-left">{{row.modelName}}</div>
          </template>
          <template slot-scope="{ row,index }" slot="modelNumber">
            <div class="text-left">{{row.modelNumber}}</div>
          </template>

          <template slot-scope="{ row,index }" slot="bdAddress">
            <div class="text-left" v-if="isDeviceList && changeableBDAddress()">
              <a href="" @click.prevent.stop="showChangeBdAddress({...row,index:index})" v-show="true">{{ row.bdAddress }}</a>
            </div>
            <div v-else class="text-left">
              {{ row.bdAddress }}
            </div>
          </template>
          <template slot-scope="{ row,index }" slot="created">
            <div class="text-left">{{row.created}}</div>
          </template>


          <template slot-scope="{ row,index }" slot="rent_from">
            <div class="text-left">{{row.rent_from}}</div>
          </template>

          <template slot-scope="{ row,index }" slot="lostFlg">
            <div class="text-center">
              <CButton size="sm" color="success mr-1" variant="outline" :pressed="row.lostFlg" @click="lost(row.lostFlg,row.deviceId)" v-show="showLostDiscard">紛失</CButton>
            </div>
          </template>

          <template slot-scope="{ row,index }" slot="discardFlg">
            <div class="text-center">
              <CButton size="sm" color="success mr-1" variant="outline" :pressed="row.discardFlg"
                       @click="discard(row.discardFlg,row.deviceId)" v-show="showLostDiscard">廃棄</CButton>
            </div>
          </template>

        </BeeGridTable>
      </CCardBody>
    </CCard>
  </div>

</template>

<script>


import BeeMix from '@/views/aprod/js/mixin/beeUtils'
import MyBeeHeader from "@/component/parts/MyBeeHeader";
import MyBeeSearchBar from "@/component/parts/MyBeeSearchBar";
export default {
  components: {
    MyBeeHeader, MyBeeSearchBar
  },
  mixins: [BeeMix],
  props: {
    isShow: { default: false, type: Boolean },
    showLostDiscard: { default: false, type: Boolean },
    showDownload: { default: false, type: Boolean },
    // isCollapsedNested: { default: false, type: Boolean },
    isCollapsedNested: { default: false, type: Boolean },
    showDeviceSelect: { default: false, type: Boolean },
    deviceSelectFlg:{ default:false,type:Boolean},
    isDeviceList: { default: false, type: Boolean },
  },
  data: () => {
    return {
          columns: [
            {key: 'modelName',slot:'modelName',headSlot:"modelNameHead",title: '機種区分',align: 'center'},
            {key: 'modelNumber',slot:'modelNumber',headSlot:"modelNumberHead", title: '機種',align:'center'},
            {key: 'serialNumber',slot:"serialNumber", headSlot:"serialNumberHead", title: 'シリアル番号',align:'center'},
            {key: 'bdAddress',slot:'bdAddress',headSlot:"bdAddressHead", title: 'BDアドレス',align:'center'},
            {key: 'created',slot:'created',headSlot:"createdHead", title: '登録日時',align:'center'},
            {key: 'patientName', title: '患者名',slot:"patientName",align: 'center',headSlot:"patientNameHead"},
            {key: 'patientId',slot:'patientId',headSlot:"patientIdHead",align: 'center', title: '登録No'},
            {key: 'agencyName',slot:'agencyName',headSlot:"agencyNameHead", title: '販売店',align: 'center'},
            {key: 'agencyBranchName',slot:'agencyBranchName',headSlot:"agencyBranchNameHead", title: '販売店事業所',align:'center'},
            {key: 'agencyId',slot: 'agencyId',headSlot:"agencyIdHead", title: '販売店ID',align: 'center'},
            {key: 'gw_now_firmware_id',slot: 'gw_now_firmware_id',headSlot: 'gw_now_firmware_idHead', title: 'ファームウェアID',align:'center'},
            {key: 'rent_from',slot: 'rent_from',headSlot: 'rent_fromHead', title: 'ペアリング日時'},
            {key: 'lostFlg',slot:"lostFlg",headSlot:"lostFlgHead",width:80, title: '紛失',align:'center'},
            {key: 'discardFlg',slot:"discardFlg",headSlot:"discardFlgHead",width:80, title: '廃棄',align:'center'}
          ],
          searchParams:{},
          searchTargets: [],
          // modelNumbers:["5A+","TCL-003","TM1121","UA-651BLE"],
          modelNumbers:["","","",""],
          deviceOptions: [
            {value: 'TCL-003', label: 'IoTゲートウェイ(TCL-003*.csv)'},
            {value: 'UA-651BLE', label: '血圧計(UA-651BLE*.csv)'},
            {value: 'TM1121', label: 'パルスオキシメータ(TM1121*.csv)'},
            {value: '5A+', label: '酸素濃縮装置(5A+*.csv)'},
            {value: '5B+', label: '酸素濃縮装置(5B+*.csv)'},
          ],
          patientDialogData:{ patientName: null, karuteNo: null,patientTel:null, hospitalName: null,
            doctorName: null, hospitalAddress: null, tel: null, deviceList: [] },
          patientModal: false,
          bdAddressChageModal: false,
          changeBdAddress:null,
          changeSerialNumber:null,
          searchBarPlaceHolder:"患者名、販売店、販売店ID、販売店事業所、シリアル番号、BDアドレス、登録No、ファームウェアIDで検索",
          allBranchFlg:false,
          bdAddressChangeTarget:null,
          serialNumberChangeTarget: null,
          serialChangeModal: false
        }
  },
  mounted() {
    if(this.isRole(['REGISTRATOR','MAINTENANCE'])){
      this.hiddenColumn("patientName");
    }
    if(this.showLostDiscard == false){
      this.hiddenColumn('lostFlg');
      this.hiddenColumn('discardFlg');
      // this.hiddenColumn('gw_now_firmware_id');
      // this.hiddenColumn('patientName');
      this.hiddenColumn('patientId');
    }
    if(!(this.isAdministrator() || this.isRegistrator())) {
      this.hiddenColumn('created');
    }
  },
  methods: {
    deviceSelect(row){
      this.$emit("deviceSelect",row);
    },
    showPatientDetail(data) {
      this.axios.get(`/api/patientInfoDialog/${data.patientId}`).then(response => {
        this.patientDialogData = response.data.result;
        this.patientModal = true;
      }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);
    },
    showChangeBdAddress(row) {
      this.bdAddressChangeTarget = row;
      this.bdAddressChageModal = true;
      //alert(JSON.stringify(data));
      // this.axios.get(`/api/patientInfoDialog/${data.patientId}`).then(response => {
      //   this.patientDialogData = response.data.result;
      //   this.patientModal = true;
      // }).catch(error => console.log(error)
      // ).finally(() => this.isLoading = false);


    },
    showChangeSerialNumber(row) {
      this.serialNumberChangeTarget = row;
      this.serialChangeModal = true;
    },

    bdAddressChange(){
      if (this.changeBdAddress == null && this.changeBdAddress.trim() == "") {
        alert("BDアドレスを入力してください。");
        return;
      }
      this.axios.post('/api/device/changeBDAddress', {deviceId:this.bdAddressChangeTarget.deviceId,bdAddress:this.changeBdAddress},
          {headers: {'Content-Type': 'application/json'}}).then(response => {
        //登録
        this.rows[this.bdAddressChangeTarget.index] = Object.assign(this.rows[this.bdAddressChangeTarget.index],{"bdAddress":response.data.result.bdAddress});
      }).catch(error => console.log(error)
      ).finally(() => {
        this.bdAddressChageModal = false;
        this.isLoading = false;
      });
    },serialNumberChange(){
      if (this.changeSerialNumber == null && this.changeSerialNumber.trim() == "") {
        alert("シリアル番号を入力してください。");
        return;
      }
      this.axios.post('/api/device/changeSerialNumber', {deviceId:this.serialNumberChangeTarget.deviceId,serialNumber:this.changeSerialNumber},
          {headers: {'Content-Type': 'application/json'}}).then(response => {
        //登録
        this.rows[this.serialNumberChangeTarget.index] = Object.assign(this.rows[this.serialNumberChangeTarget.index],{"serialNumber":response.data.result.serialNumber});
      }).catch(error => console.log(error)
      ).finally(() => {
        this.serialChangeModal = false;
        this.isLoading = false;
      });
    },
    csvDownload(){
      // this.$emit("csvDownload",this.searchParams);
      //検索した時点のデータを取得するためthis.searchParamsを使う
      this.axios.post('/api/device/deviceList/csvDownload',
          this.searchParams
          , {headers: {'Content-Type': 'application/json'}, responseType: 'blob'}).then(
          response => {
            let url = window.URL.createObjectURL(new Blob([response.data]));
            let link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', decodeURI(response.headers.filename));
            document.body.appendChild(link);
            link.click();
          }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);



    },toggleAllBranch(){
      this.allBranchFlg = !this.allBranchFlg;
      this.goodPaging();
    },lost(lostFlg,deviceId){
      this.$emit("lost",lostFlg,deviceId);
    },discard(discardFlg,deviceId){
      this.$emit("discard",discardFlg,deviceId);
    },
    // showPatientDetail(row){
    //   this.$emit("showPatientDetail",row);
    // },
    goodPaging(isPageChage) {

      if (!isPageChage) this.pagingParams.currPage = 1;//ページが以外が変わったら最初から検索する
      this.isLoading = true;

      let searchParams = {modelNumbers:this.modelNumbers,pagingForm:this.pagingParams,deviceSelectFlg:this.deviceSelectFlg,
        allBranchFlg:this.allBranchFlg};

      this.axios.post('/api/device/searchDeviceListPaging',
          searchParams
          , {headers: {'Content-Type': 'application/json'}}).then(response => {
        this.totalRecords = response.data.result.totalElements;
        this.rows = response.data.result.content;
        //ページ件数
        this.pages = response.data.result.totalPages;
        this.searchParams = searchParams;
        this.topLeft();
      }).catch(error => console.log(error)
      ).finally(() => {
        this.isLoading = false;
      });
    },changeableBDAddress(){
      return this.isAdministrator() || this.isRegistrator();
    },changeableSerialNumber(){
      return this.changeableBDAddress()
    }
  },
  watch:{
    showLostDiscard(){
      if(!this.showLostDiscard){
        this.hiddenColumn("lostFlg");
        this.hiddenColumn("discardFlg");
      }
    },
    isShow:function(){
      if (this.isShow) {
        this.goodPaging();
      }
    },
    modelNumbers:function(){
      this.goodPaging();
    }
  }
}

</script>
